import { StyledModal } from 'app/common';
import { ArchivingModalsEnum, useArchivingInternal } from 'app/fields/archiving/archiving-internal.provider';
import { Blue, Neutral } from 'app/theme';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components';

export function ArchiveError() {
  const { t } = useTranslation();

  const {
    loading,
    modals: { [ArchivingModalsEnum.ARCHIVE_ERROR]: open },
    fieldsInActiveSeason,
    selectedFieldsIds,
    resetState,
    goToSeasons,
    archiveFields
  } = useArchivingInternal();

  const nrOfFields = selectedFieldsIds.length;
  const nrOfFieldsInActiveSeason = fieldsInActiveSeason.length;
  const hasAvailableFieldsToArchive = nrOfFields > nrOfFieldsInActiveSeason;

  const onClose = () => {
    resetState();
  };

  const onConfirm = () => {
    if (hasAvailableFieldsToArchive) {
      archiveFields();
    } else {
      resetState();
      goToSeasons();
    }
  };
  return (
    <StyledModal
      loading={loading}
      open={open}
      error={true}
      onClose={onClose}
      title={t('archiving.notifications.archive_error.title', { count: nrOfFields })}
      cancel={{
        onClick: onClose,
        text: t('common.button.cancel')
      }}
      ok={{
        onClick: onConfirm,
        text: hasAvailableFieldsToArchive
          ? t('archiving.notifications.archive_error.archive_fields_button')
          : t('archiving.notifications.archive_error.go_to_crop_seasons_button')
      }}>
      <div style={{ paddingLeft: 40 }}>
        <span>
          {t('archiving.notifications.archive_error.description', {
            count: nrOfFields,
            countInvalid: fieldsInActiveSeason.length
          })}
        </span>
        <FieldsList>
          <VariableSizeList
            height={Math.min(30 + (fieldsInActiveSeason.length - 1) * 34, 150)}
            width={'100%'}
            itemCount={fieldsInActiveSeason.length}
            itemSize={(index) => (index === 0 ? 30 : 34)}>
            {({ index, style }) => {
              const { name, season } = fieldsInActiveSeason[index];
              return (
                <FieldContainer style={style}>
                  <Field>
                    <b>{name}</b>
                    <Season>
                      <span>{t('Crop cycle')}: </span>
                      <b>{season}</b>
                    </Season>
                  </Field>
                </FieldContainer>
              );
            }}
          </VariableSizeList>
        </FieldsList>
        {nrOfFields === 1 ? (
          t('archiving.notifications.archive_error.description_confirmation_one')
        ) : (
          <Trans
            key='archiving.notifications.archive_error.description_confirmation'
            count={nrOfFields}>
            To archive these fields,{' '}
            <LinkBtn onClick={goToSeasons}>go to Crop Seasons page</LinkBtn> and remove them from
            the active crop season.
          </Trans>
        )}
      </div>
    </StyledModal>
  );
}

const FieldsList = styled.div`
  margin: 12px 0;
`;

const FieldContainer = styled.div`
  padding-right: 4px;

  & + & {
    margin-top: 4px;
  }
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border: 1px solid ${Neutral[20]};
  border-radius: 2px;
  line-height: 20px;
`;

const Season = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: ${Neutral[60]};
    font-size: 12px;
  }
`;

const LinkBtn = styled.span`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;

  color: ${Blue[50]};
`;
