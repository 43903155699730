import { useRef } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const Analytics = new AnalyticsBrowser();

/**
 * Calls the Analytics library init function.
 *
 * @param clientId Amplitude Browser SDK clientId
 */
export function initAnalytics(clientId: string) {
  Analytics.load({ writeKey: clientId });
}

type PropertiesObject = { [key: string]: unknown };
type UseAnalyticsReturn = (eventName: string, eventProperties: PropertiesObject) => void;
type ContextCallback = () => PropertiesObject;

/**
 * Custom hook for analytics tracking.
 * 
 * @param callbacks - An array of callback functions that provide additional context properties for tracking.
 * @returns A function to track analytics events.
 */
const useAnalytics = (...callbacks: ContextCallback[]): UseAnalyticsReturn => {
  const mergeProperties = (eventProperties: PropertiesObject) => {
    const mergedContexts: PropertiesObject = {};

    for (const cb of callbacks) {
      const cbContext = cb();
      Object.assign(mergedContexts, cbContext);
    }

    return { ...mergedContexts, ...eventProperties };
  };

  const track = useRef((eventName: string, eventProperties: PropertiesObject = {}) => {
    Analytics.track(eventName, mergeProperties(eventProperties));
  });

  return track.current;
};

type ComposedTrackingFn = {
  (...contexts: ContextCallback[]): UseAnalyticsReturn;
  __arguments__: (ContextCallback | ComposedTrackingFn)[];
};
const isContextualTracking = (fn: ContextCallback | ComposedTrackingFn): fn is ComposedTrackingFn =>
  typeof (fn as ComposedTrackingFn).__arguments__ !== 'undefined';

/**
 * Composes multiple sets of analytics context callbacks.
 *
 * @param additionalContexts - Additional context callbacks to include in the composition.
 * @returns A function that accepts context callbacks and returns a composed analytics tracking function.
 */
export const createAnalyticsHook = (
  ...additionalContexts: (ContextCallback | ComposedTrackingFn)[]
): ComposedTrackingFn => {

  const recursiveFlatMapArguments = (
    callbacks: (ContextCallback | ComposedTrackingFn)[]
  ): ContextCallback[] =>
    callbacks.flatMap((arg) =>
      isContextualTracking(arg) ? recursiveFlatMapArguments(arg.__arguments__) : arg
    );

  const useComposed = (...contexts: ContextCallback[]) => {
    const composedContexts = recursiveFlatMapArguments(additionalContexts);

    return useAnalytics(...composedContexts, ...contexts);
  };

  useComposed.__arguments__ = additionalContexts;

  return useComposed;
};
