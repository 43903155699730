import * as Rox from 'rox-browser';
import { SETUP_ROLLOUT, SETUP_ROLLOUT_SUCCESS, SETUP_ROLLOUT_ERROR } from './types';

// Create a Roxflag in the flags container class
const featureFlags = {
  isFieldVersionFeatureVisible: new Rox.Flag(true),
  isTimelineFeatureVisible: new Rox.Flag(true),
  isNoBoundaryCreationEnable: new Rox.Flag(true),
  isRBACVerificationEnabledOnBaseFront: new Rox.Flag(true),
  isJDOCEnabled: new Rox.Flag(false),
  isSignLegelDocumentsEnabled: new Rox.Flag(false),
  isInviteMembersEnabledOnFront: new Rox.Flag(true),
  isUserFromEMEA: new Rox.Flag(false),
  isUserFromCanada: new Rox.Flag(false),
  isCropRotationCyclesFeatureEnabled: new Rox.Flag(false),
  isHideAllEnabled: new Rox.Flag(false),
  'Atlas.useEventDateOverIdVersion': new Rox.Flag(false),
  PlansWithResourceFilter: new Rox.RoxString(""),
  'FarmSettings.isBufferToolEnabled': new Rox.Flag(false),
  'FarmSettings.genericItems': new Rox.Flag(false),
  'FarmSettings.productTab': new Rox.Flag(false),
  'FarmSettings.seedsVariety': new Rox.Flag(false),
  'FarmSettings.accountsUsingAsyncService': new Rox.RoxString(''),
  'FarmSettings.secondsBeforeQueueAsyncService': new Rox.RoxNumber(10),
  isBuildingResourceVisible_P40_37066: new Rox.Flag(true),
  isTaxIdVisible_P40_37340: new Rox.Flag(false)
};

export const getFeatureFlags = (context) => {
  const targetObj = {};
  Object.keys(featureFlags).forEach((flagKey) => {
    // eslint-disable-next-line
    if (featureFlags[flagKey]._type === 'boolean') {
      targetObj[flagKey] = featureFlags[flagKey].isEnabled(context);
    } else {
      targetObj[flagKey] = featureFlags[flagKey].getValue(context);
    }
    return flagKey;
  });
  return targetObj;
};

export function initRollout(configs) {
  const { forceDefault, userContext } = configs || {};
  return (dispatch) => {
    try {
      dispatch({ type: SETUP_ROLLOUT });
      if (!Rox.flags?.length) {
        Rox.register('', featureFlags);
      }
      if (forceDefault) {
        dispatch(rolloutSuccess(getFeatureFlags(featureFlags)));
      } else {
        // Set global context
        Rox.setContext(userContext);
        Rox.setCustomStringProperty('user.id', userContext.id);
        if (userContext.userLocation && userContext.userLocation.country_code) {
          Rox.setCustomStringProperty('user.country_code', userContext.userLocation.country_code);
        }
        return Rox.setup(process.env.REACT_APP_ROLLOUT_KEY, {})
          .then(() => {
            setTimeout(() => {
              // eslint-disable-next-line
              console.log('featureFlags initialized - ', getFeatureFlags(featureFlags));
              dispatch(rolloutSuccess(getFeatureFlags(featureFlags)));
            }, 100);
          })
          .catch((error) => {
            return dispatch(rolloutError(error));
          });
      } 
    } catch (error) {
      return dispatch(rolloutError(error))
    }
  }
};

function rolloutSuccess(payload) {
  return {
    type: SETUP_ROLLOUT_SUCCESS,
    payload: payload
  };
}
function rolloutError(error) {
  return {
    type: SETUP_ROLLOUT_ERROR,
    error
  };
}
