/* eslint-disable indent */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { iff } from '../../utils/iff';
import { split } from 'lodash';

const StyledIcon = styled.span`
  &::before {
    color: ${(props) => props.color || '#14151C'};
    font-size: ${(props) =>
      iff(
        props.size !== undefined && props.size !== null && props.size !== '',
        `${props.size}px`,
        'inherit'
      )};
  }
`;

const IconComponent = ({ size, name, color, style = {}, extraClassName }) => {
  return (
    <>
      {iff(
        name !== null && name !== undefined && name !== '' && split(name, ' ').length === 1,
        <StyledIcon
          style={{ ...style }}
          className={`${`icon-${name}`}${iff(
            extraClassName !== null && extraClassName !== undefined && extraClassName !== '',
            ` icon-${extraClassName}`,
            ''
          )}`}
          color={color}
          size={size}
        />,
        <StyledIcon
          style={{ ...style }}
          className={`icon-phenology-1 ${iff(
            extraClassName !== null && extraClassName !== undefined && extraClassName !== '',
            `icon-${extraClassName}`,
            ''
          )}`}
          color={color}
          size={size}
        />
      )}
    </>
  );
};

IconComponent.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  extraClassName: PropTypes.string
};

export const FontIcon = memo(IconComponent);
