import { Neutral } from "app/theme";
import styled, { css } from "styled-components";


export const Button = styled.button<{ $dark: boolean; $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 16px;
  height: 100%;
  border: 1px solid;
  border-radius: 4px;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  border-color: ${Neutral[0]};
  color: ${Neutral[0]};

  svg * {
    fill: ${Neutral[0]};
  }

  &:hover {
    border-color: ${Neutral[40]};
  }

  ${({ $dark, $disabled }) =>
    $dark &&
    !$disabled &&
    css`
      border-color: ${Neutral[30]};
      color: ${Neutral[100]};

      svg * {
        fill: ${Neutral[60]};
      }

      &:hover {
        border-color: ${Neutral[100]};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      border-color: ${Neutral[40]};
      color: ${Neutral[70]};
      opacity: 0.6;

      svg * {
        fill: ${Neutral[60]};
      }
    `}
`;