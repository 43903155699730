import styled from 'styled-components';

import { iff } from '../../../utils/iff';

export const StyledContainer = styled.div`
  padding: 24px;
  height: ${(props) => iff(props.showAttention === true, '55%', '83%')};
  overflow: auto;
  .input {
    .ant-select-selector {
      background: #f3f4f6;
      border: 1px solid #c2c7d0;
      box-sizing: border-box;
      border-radius: 4px;
      color: #14151c;
    }
  }
  .syt-input-number {
    background: #f3f4f6;
    border: 1px solid #c2c7d0;
    box-sizing: border-box;
    border-radius: 4px;
    color: #14151c;
  }
  .form-container {
    margin-bottom: 18px;
  }
  .form-container > .ant-form-item-control > .ant-form-item-explain,
  .ant-form-item-extra {
    margin-top: 0px;
  }
  .crop-cycle-label {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.0075em;
    color: #868ca2;
  }
  .input
    > .ant-select-selector
    > .ant-select-selection-item
    > .ant-select-selection-item-content
    > div
    > span {
    display: none;
  }
  .input > .ant-select-selector > .ant-select-selection-item > .ant-select-selection-item-remove {
    display: none;
  }
  .edit-crop-cycle-label label {
    width: 100%;
  }
  .edit-crop-cycle-label label.ant-checkbox-wrapper {
    width: auto;
  }
  .edit-crop-cycle-label label .edit-crop-cycle-label-icon {
    margin-left: 5px;
  }
`;
