import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { history, URL_CONSTANTS } from 'utils/history';
import { ArchiveIcon } from '../../archive.icon';
import { useParams } from 'react-router';
import { Neutral } from 'app/theme';

export function OpenArchivedButtonContainer() {
  const { id: orgId, farmId } = useParams();
  const { farmSettingsArchivingCws10301EnableArchive } = useFlags();
  const { t } = useTranslation();

  if (!farmSettingsArchivingCws10301EnableArchive) return null;

  return (
    <StyledButton
      onClick={() => {
        history.push(
          URL_CONSTANTS.ARCHIVED_FIELDS({ orgId: orgId as string, farmId: farmId as string })
        );
      }}>
      <ArchiveIcon />
      {t('archiving.open_archived_button')}
    </StyledButton>
  );
}

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 12px;
  height: 32px;
  border: 1px solid;
  border-radius: 4px;
  background: none;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  border-color: ${Neutral[30]};
  color: ${Neutral[100]};

  svg * {
    fill: ${Neutral[70]};
  }

  &:hover {
    border-color: ${Neutral[100]};
  }
`;
