import * as Sentry from '@sentry/react';
import { RouterHistory } from '@sentry/react/build/types/reactrouter';
import { store } from 'redux/reducer/store';
import { history } from 'utils/history';

export const SENTRY_KEY = process.env.REACT_APP_SENTRY_DSN ?? '';
const APP_ENVIRONMENT = process.env.REACT_APP_envName ?? '';
const SAMPLE_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? 1.0;
const NORMALIZE_DEPTH = 5;

export const SENTRY_CONFIG: Sentry.BrowserOptions = {
  dsn: SENTRY_KEY,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history: history as unknown as RouterHistory })
  ],
  tracesSampleRate: Number(SAMPLE_RATE),
  environment: APP_ENVIRONMENT,
  normalizeDepth: NORMALIZE_DEPTH,
  beforeSend(event) {
    const state = store.getState();
    const hasProperty =
      window.location.pathname.includes('property') ||
      window.location.pathname.includes('edit-farm');
    const farmId = hasProperty ? window.location.pathname.split('/')?.[5] ?? undefined : undefined;

    const organization = state?.orgs?.orgById;
    const workspace = state?.user?.currentworkspaceInfo?.id
      ? state?.user?.currentworkspaceInfo
      : state.connectedDecks.data
          .map(({ workspace_id, name }: any) => ({ id: workspace_id, name }))
          .find(({ id }: any) => id === state?.orgs?.orgById.workspace_id) ??
        {} ??
        {};
    const property = farmId
      ? state.propertiesList?.data?.entities?.properties?.[farmId]
      : undefined;
    const user = state.user.userProfile;

    event.tags = {
      workspace_id: workspace?.id,
      workspace_name: workspace?.name,
      organization_id: organization?.id,
      organization_name: organization?.name,
      organization_country: organization?.country,
      property_id: property?.id,
      property_name: property?.name,
      property_country: property?.regulatory_zone,
      user_id: user?.id,
      user_email: user?.email,
      user_name: user?.name
    };

    return event;
  }
};
