/* eslint-disable */

import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { CookieStorage } from 'cookie-storage';
import jwtDecode from 'jwt-decode';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import momentTimezone from 'moment-timezone';
import React from 'react';
import center from '@turf/center';
import intersect from '@turf/intersect';
import { getCoords } from '@turf/invariant';

import { DEFAULT_LATLONG } from '../pages/properties/create/autoFillHandler';
import { AppConstants, SYSTEM_RESOURCE_RBAC } from '../utils/app.constants';
import { getErrorMessageByCode } from './getErrorMessageByCode';
import { getLatLongByPropId } from './getLatLongByPropId';
import { getFieldStatusWithColor } from './isFieldDateIncludesTodaysDate';
import { iff } from './iff';
import { store } from 'redux/reducer/store';
import { Buffer } from 'buffer';
import { captureMessage } from '@sentry/react';

const cookieStorage = new CookieStorage();
const qaLinkConstant = process.env.REACT_APP_ACCOUNTS_MANAGEMENT_URL?.replace('https://', '') ?? '';

export const isAccessTokenAvailable = () => !!localStorage.getItem('tokens');
export const getAccessToken = () => localStorage.getItem('tokens') || '{}';
export const clearAccessToken = () => localStorage.removeItem('tokens');
export const isValidToken = () => {
  const token = getAccessToken()
  const tokenAsObject = JSON.parse(token)
  if(!token || !tokenAsObject?.access_token){
    return false
  }

  const accessToken = jwtDecode(tokenAsObject.access_token)
  const tokenExpirationEpoch = accessToken.exp; 
  const currentTimeEpoch = Math.floor(Date.now() / 1000);

  return currentTimeEpoch < tokenExpirationEpoch
}

//set moment object timezone to the passed timezone, if not passed set to local timezone as default
export const setMomentDateTimeZone = (
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  momentTimezone.tz.setDefault(timeZone);
};

/**
 * @param {*}
 * date -> it can be date js object or normal date string
 * timezone -> timezone(property timezone)
 * @returns converted moment date object with specified timezone
 */
export const getDateWithPassedTimeZone = (
  date,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  if (date) {
    return momentTimezone(date).tz(timeZone);
  } else {
    return null;
  }
};

const getDomain = () =>
  document.location.hostname.includes('cropwise.com') ? 'cropwise.com' : 'syngentadigital.co.uk';

export const cleanNewUserSession = () => {
  const env = process.env.REACT_APP_envName;
  const key = `resetUserSession_${env}`;
  if (cookieStorage.getItem(key)) {
  }
};

export const setLocalUsingCookies = (lang) => {
  const env = process.env.REACT_APP_envName;
  if (env) {
    const key = `i18nextLng_${env}`;
    const domain = getDomain();
    cookieStorage.setItem(key, lang, {
      path: '/',
      domain
    });
  }
};

export const getLocaleUsingCookies = () => {
  const env = process.env.REACT_APP_envName;
  const key = `i18nextLng_${env}`;
  return cookieStorage.getItem(key);
};

export const setLocaleTempUsingCookies = (lang) => {
  const env = process.env.REACT_APP_envName;
  if (env) {
    const key = `i18nextLngTemp_${env}`;
    const domain = getDomain();
    cookieStorage.setItem(key, lang, {
      path: '/',
      domain
    });
  }
};

export const getLocaleTempUsingCookies = () => {
  const env = process.env.REACT_APP_envName;
  const key = `i18nextLngTemp_${env}`;
  return cookieStorage.getItem(key) || '';
};

const tokenBelongsToAuthority = (authorizationToken, authority) => {
  try {
    const decoded = jwtDecode(authorizationToken);
    return (decoded.authorities || []).indexOf(authority) > -1;
  } catch (err) {
    return false;
  }
};

export const isUserSupportOrSuperUser = () => {
  try {
    const token = JSON.parse(getAccessToken());
    return (
      tokenBelongsToAuthority(token.access_token, 'SUPER_USER') ||
      tokenBelongsToAuthority(token.access_token, 'SUPPORT')
    );
  } catch (err) {
    return false;
  }
};

export const isSupportUser = () => {
  try {
    const token = JSON.parse(getAccessToken());
    return tokenBelongsToAuthority(token.access_token, 'SUPPORT');
  } catch (err) {
    return false;
  }
};

export const isSuperUser = () => {
  try {
    const token = JSON.parse(getAccessToken());
    return tokenBelongsToAuthority(token.access_token, 'SUPER_USER');
  } catch (err) {
    return false;
  }
};

export const isEmptyStr = (str) => {
  if (typeof str === 'string' && str.length > 0) {
    return true;
  }
  return false;
};

export const getHeaders = () => ({
  common: {
    Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`
  }
});

export const getStoredLanguage = () => {
  let lang = getLocaleUsingCookies() || localStorage.getItem('i18nextLng') || 'en';
  if (lang === 'pt') {
    lang = 'pt-BR';
  }
  return lang;
};

export const getLangHeaders = (overrideLanguage) => {
  const lang = getStoredLanguage();
  return {
    common: {
      Authorization: `Bearer ${JSON.parse(getAccessToken()).access_token}`,
      'Accept-Language': overrideLanguage || lang
    }
  };
};

export const getInitialName = (name) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()} ${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const getShortName = (name) => {
  if (isEmpty(name)) {
    return '';
  }
  const splitedNames = name.split(' ');
  const firstName = first(splitedNames) || '';
  const lastName = last(splitedNames) || '';
  if (splitedNames.length >= 2) {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }
  return firstName.charAt(0).toUpperCase();
};

export const setTimeoutPromise = async (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const isSearchSubStringFound = (searchString = '', fields = []) =>
  !isEmpty(
    fields.filter((field) => (field || '').toLowerCase().indexOf(searchString.toLowerCase()) >= 0)
  );

export const getAppHostsInfo = () => {
  const domainMapping = {
    'localhost:3000': { host: 'GLOBAL', account: qaLinkConstant },
    'localhost:4000': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'localhost:5000': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'beta.app.cropwise.com': { host: 'GLOBAL', account: 'beta.accounts.cropwise.com' },
    'beta.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'beta.accounts.syngentadigital.co.uk'
    },
    'dev.app.cropwise.com': { host: 'GLOBAL', account: 'dev.accounts.cropwise.com' },
    'uk-dev.app.cropwise.com': { host: 'UK', account: 'uk-dev.accounts.cropwise.com' },
    'dev.app.syngentadigital.co.uk': { host: 'UK', account: 'dev.accounts.syngentadigital.co.uk' },
    'qa.app.cropwise.com': { host: 'GLOBAL', account: qaLinkConstant },
    'staff.qa.cropwise.com': { host: 'GLOBAL', account: qaLinkConstant },
    'uk-qa.app.cropwise.com': { host: 'UK', account: 'uk-qa.accounts.cropwise.com' },
    'qa.app.syngentadigital.co.uk': { host: 'UK', account: 'qa.accounts.syngentadigital.co.uk' },
    'staging.app.cropwise.com': { host: 'GLOBAL', account: 'staging.accounts.cropwise.com' },
    'uk-staging.app.cropwise.com': { host: 'UK', account: 'uk-staging.accounts.cropwise.com' },
    'staging.app.syngentadigital.co.uk': {
      host: 'UK',
      account: 'staging.accounts.syngentadigital.co.uk'
    },
    'app.cropwise.com': { host: 'GLOBAL', account: 'accounts.cropwise.com' },
    'uk.app.cropwise.com': { host: 'UK', account: 'uk.accounts.cropwise.com' },
    'app.syngentadigital.co.uk': { host: 'UK', account: 'accounts.syngentadigital.co.uk' },
    'featurereviewreact-18-upgrade-qa-cropwise-base.surge.sh': {
      host: 'GLOBAL',
      account: qaLinkConstant
    }
  };

  const { host } = window.location;
  return {
    host: (!isEmpty(domainMapping[host]) && domainMapping[host].host) || 'GLOBAL',
    account: !isEmpty(domainMapping[host]) && domainMapping[host].account,
    hostsList: [...new Set(Object.values(domainMapping).map((d) => d.host))]
  };
};

export const isUK = () => {
  const { host } = getAppHostsInfo();
  if (host === 'UK') {
    return true;
  }
  return false;
};

export const getUrl = (baseUrl, queryParams) => {
  if (queryParams && Object.keys(queryParams).length > 0) {
    let delimiter, paramValue;
    let isFirstParam = false;
    const urlWithQueryParams = Object.keys(queryParams).reduce((parameterString, key) => {
      const paramKey = encodeURIComponent(key);
      paramValue = encodeURIComponent(queryParams[key]);
      if (paramValue && !isFirstParam) {
        delimiter = '?';
        isFirstParam = true;
      } else {
        delimiter = '&';
      }

      if (paramValue || typeof val === 'number') {
        return [parameterString, delimiter, paramKey, '=', paramValue].join('');
      } else {
        return parameterString;
      }
    }, baseUrl);
    return urlWithQueryParams;
  } else {
    return baseUrl;
  }
};

export const initOAuth2Flow = (returnStateValue) => {
  const returnState = returnStateValue ? `&state=${btoa(returnStateValue)}` : '';
  const { account } = getAppHostsInfo();
  const { O_AUTH } = AppConstants;
  /* application_id set for auto sign-in with Accounts token on OAuth2Flow for Farm Settings */
  window.location.href = `https://${account}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${location.origin}/authenticate&application_id=${process.env.REACT_APP_BASE_REF_ID}${returnState}`;
};

export const getAccountsOAuthUrl = () => {
  const { account } = getAppHostsInfo();
  const { O_AUTH } = AppConstants;
  return `https://${account}/oauth/authorize?response_type=${O_AUTH.responseCode}&client_id=${O_AUTH.clientId}&redirect_uri=${location.origin}/authenticate`;
};

export const getRowsAccordingToWindowHeight = (lessRows = 0) =>
  Math.floor(
    iff(
      window.innerHeight < 800,
      window.innerHeight,
      window.innerHeight + (window.innerHeight - 800)
    ) / 100
  ) - lessRows;

export const sortByName = (sortArray) => {
  sortArray.sort(function (a, b) {
    const x = a?.name?.toLowerCase();
    const y = b?.name?.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
};

export const getLanguageCode = (lang) => {
  const selectedLang = lang || navigator.language;
  const langKeys = AppConstants.SUPPORTED_LANGS.map((lang) => lang.value);
  if (langKeys.includes(lang)) {
    return lang;
  }
  return langKeys.find((langKey) => selectedLang.includes(langKey)) || 'en';
};

export const sleep = (milliseconds) => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
};

/**
 *
 * @param {*} name : The string to truncate
 * @param {*} param1 : isCheckedCharLength : This is char length of checked list item
 * truncateLimit : This is char length of total possible length in a list
 * @returns Return a truncated string with this format "A BCD EFGH... XYZ"
 */
export const getTruncatedName = (name, { isCheckedCharLength = 0, truncateLimit = 46 }) => {
  if (!name) return '';
  if (name.length <= truncateLimit) {
    return name;
  } else {
    const splittedName = name.split(' ');
    let startCharLength = splittedName.pop();
    startCharLength = `${'... '} ` + splittedName.pop() + ' ' + startCharLength;
    return (
      name.substring(0, truncateLimit - (startCharLength.length + isCheckedCharLength + 4)) +
      startCharLength
    );
  }
};

export const isValidCallbackURI = (callbackUri) => {
  return +callbackUri.split('?')[0].split('://').length === 2;
};

export const accountsOverviewURLProdEnv = (currentWorkspace) => {
  return isUK()
    ? `https://accounts.syngentadigital.co.uk/app/workspaces?workspace=${currentWorkspace}`
    : `https://accounts.cropwise.com/app/workspaces?workspace=${currentWorkspace}`;
};

export const accountsOverviewURLOtherEnvs = (currentWorkspace, currentEnv) => {
  return isUK()
    ? `https://${currentEnv}.accounts.syngentadigital.co.uk/app/workspaces?workspace=${currentWorkspace}`
    : `https://${currentEnv}.accounts.cropwise.com/app/workspaces?workspace=${currentWorkspace}`;
};

export const openNotificationMessage = (message, description, type) => {
  notification.open({
    message: message,
    duration: 0,
    description: description,
    icon:
      type === 'error' ? (
        <CloseCircleOutlined style={{ color: '#CF3537' }} />
      ) : (
        <ExclamationCircleOutlined style={{ color: '#CF3537' }} />
      )
  });
};

export const getSearchParam = (locationSearch, param) => {
  return new URLSearchParams(locationSearch).get(param) || '';
};

// get property address from property object and set if we are passing setstate method otherwise just return it
export const setFocusBoundsThroughPropertyAddress = (data, property, setFocusBounds) => {
  const center = propertyCenterLocation(data, property);
  const bounds = {
    _southWest: {
      lat: center[1],
      lng: center[0]
    },
    _northEast: {
      lat: center[1],
      lng: center[0]
    }
  }
  setFocusBounds?.(bounds);
  return bounds;
};

export const getPropDetails = async (
  propertyId,
  setLat,
  setLong,
  setProperty,
  setErrorMessage,
  getAddressFromLatLong
) => {
  try {
    const propertyRes = await getLatLongByPropId(
      propertyId,
      setLat,
      setLong,
      DEFAULT_LATLONG,
      getAddressFromLatLong
    );
    setProperty(propertyRes.data);
  } catch (error) {
    if (!isEmpty(error) && Object.keys(error.response.data).length > 0) {
      const errorMessageByCode = getErrorMessageByCode(get(error, 'response.data.code', ''));
      setErrorMessage(errorMessageByCode);
    }
  }
};

export const getStringWithoutAccentsOrDiacritics = (value) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const decodeURI = (uri) => {
  return Buffer.from(uri, 'base64').toString();
};

export const getDecodedURI = (uri) => {
  if (uri.includes('http')) {
    return uri;
  }
  return decodeURI(uri);
};

export const fieldsToAreaArray = (fields: any[]): number[] => (
  fields.map((field: any) => field?.calculated_area !== 0 ? field?.calculated_area ?? 0 : field?.declared_area)
)

export const isInViewport = (el) => {
  try {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  } catch (e) {
    return false;
  }
};

/**
 * filter variety dropdown options
 * @param {*} input
 * @param {*} option
 * @returns
 */
export const handleFilterOption = (input, option) => {
  const keyToCheck = iff(
    'options' in option,
    'options.children.props.children',
    'children.props.children'
  );
  const filterOptionValue = get(option, keyToCheck, []);
  return (
    filterOptionValue.length === 2 &&
    filterOptionValue[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
};

/**
 * add language fallback for translations
 * suppose if a key is not exist in any file, will search in fallback lanhuage file
 * @param {*} code
 * @param {*} fallback
 * @returns
 */
const addFallbackForTranslations = (code, t, params = {}, fallback = 'en') => {
  let translation = t(code, params);
  if (translation === code) {
    translation = t(code, {
      ...params,
      lng: fallback
    });
  }
  return translation;
};

/**
 * translation wrapper
 * @param {*} translation
 * @returns
 */
export const translationWrapper = (translation, addKeyFromSession = true) => {
  const { t } = translation;
  const getTranslation = (key, params = {}) => {
    if (key) {
      return addFallbackForTranslations(key, t, params);
    }
    return '';
  };
  return { t: getTranslation };
};

export const handleMembersRedirection = (e, currentWorkspace) => {
  const { account: accountURL } = getAppHostsInfo();
  const reduxState = store.getState();
  if (e.key === 'invite_member' && reduxState.rollout.flags.isInviteMembersEnabledOnFront) {
    const callback_url = window.location.href;
    const domain = window.location.origin;
    window.location.href = `${domain}/app/deep-actions/invite-members?workspace=${currentWorkspace}&invite_level=workspace&callback_uri=${callback_url}`;
  } else if (e.key === 'see_member') {
    window.location.href = `https://${accountURL}/app/workspaces/members?id=${currentWorkspace}`;
  }
  return true;
};

export const rbacCheckPermissionInVerifiedPermissions = (action, allowedOperations, resource) => {
  for (let allowedOperation of allowedOperations) {
    if (allowedOperation.action === action && allowedOperation.resource === resource) return true;
  }
  return false;
};

export const workspaceResourceForRbac = (workspaceId) => {
  return `crn:workspace:${workspaceId}`;
};

export const organizationResourceForRbac = (workspaceId, organizationId) => {
  return `${workspaceResourceForRbac(workspaceId)}/org/${organizationId}`;
};

export const propertyResourceForRbac = (workspaceId, organizationId, propertyId) => {
  return `${organizationResourceForRbac(workspaceId, organizationId)}/property/${propertyId}`;
};

const getResourceAttachmentIdsForWorkspaceLevel = (resourceAttachment) => {
  if (resourceAttachment.includes(SYSTEM_RESOURCE_RBAC)) {
    return null;
  }
  const workspaceId = resourceAttachment.split(':')[2].split('/')[0];
  const orgId = resourceAttachment.split(':')[2].split('/')[2];
  const propertyId = resourceAttachment.split(':')[2].split('/')[4];
  if (propertyId) {
    return {
      workspace_id: workspaceId,
      org_id: orgId,
      property_id: propertyId
    };
  }
  if (orgId) {
    return {
      workspace_id: workspaceId,
      org_id: orgId
    };
  }
  return {
    workspace_id: workspaceId
  };
};

const getResourceAttachmentIdsForAppLevel = (resourceAttachment) => {
  if (resourceAttachment.includes(SYSTEM_RESOURCE_RBAC)) {
    return null;
  }
  const appId = resourceAttachment.split(':')[2].split('/')[0];
  return {
    app_id: appId
  };
};

export const getResourceAttachmentIdsByLevel = (resourceAttachment, level) => {
  switch (level) {
    case 'workspace':
      return getResourceAttachmentIdsForWorkspaceLevel(resourceAttachment);
    case 'app':
      return getResourceAttachmentIdsForAppLevel(resourceAttachment);
    default:
      return null;
  }
};

export const propertyCenterLocation = (data, property) => {
  if (data?.length !== 0) {
    const featCollection = {
      type: 'FeatureCollection',
      features: data.filter((geo) => {
        if (geo.geometry) {
          geo.type = 'Feature';
          return true;
        }
        return false;
      })
    };
    if (featCollection?.features?.length !== 0) {
      return getCoords(center(featCollection));
    } else {
      return get(property, 'reference_point.coordinates', []);
    }
  } else {
    return get(property, 'reference_point.coordinates', []); // || DEFAULT_COORDINATES
  }
};

/**
 *
 * @param {*} key
 * @returns
 */
export const getCountrySpecificData = (key) => {
  const { host, hostsList } = getAppHostsInfo();
  const APPEND_KEY = iff(hostsList.includes(host), host, 'GLOBAL');
  return `common.app_branding.${key}_${APPEND_KEY}`;
};

/**
 *
 * @param {*} location
 * @returns
 */
export const findCreationMode = (location) => {
  const path = location.pathname ? location.pathname.toString().split('/') : '';
  if (path[path.length - 1] === 'draw-field') {
    return 'Draw';
  } else if (path[path.length - 1] === 'uploader') {
    return 'File Upload';
  } else if (path[path.length - 1] === 'detect-fields') {
    return 'Field Detection';
  } else {
    return 'true';
  }
};

/**
 *
 * @param {*} parameterName
 * @returns
 */
export const getParamValue = (parameterName) => {
  let result = null,
    tmp = [];
  let items = location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) {
      result = decodeURIComponent(tmp[1]);
    }
  }
  return result;
};

/**
 * Called Workspace and orgs API sequentially
 * to ensure we have all the required workspace query parameters before getting the orgs(SB-2999)
 * @param {*} param0
 */
export const getWorkspaceAndOrgsSequentially = async ({
  fetchOrgsList = () => null,
  fetchUserWorkspace = () => null
}) => {
  try {
    await fetchUserWorkspace();
    await fetchOrgsList();
  } catch (e) {
    //Error handling done in redux actions
  }
};

/**
 * get users date format
 * @param {*} props
 * @returns
 */
export const getUsersDateFormat = (props) => {
  let dateFormat = AppConstants.DATE_FORMATS_SETTINGS[0];
  const { profileSettingsDateFormat, userProfileDateFormat } = props;
  if (profileSettingsDateFormat) {
    dateFormat = props.profileSettingsDateFormat;
  } else if (userProfileDateFormat) {
    dateFormat = props.userProfileDateFormat;
  }
  return dateFormat;
};

export const getSigpacExtendedAttributes = (contextItems = []) => {
  let sigpacCode = 'PROVINCIA.MUNICIPIO.AGREGADO.ZONA.POLIGONO.PARCELA.RECINTO';
  const attributes = {};
  contextItems.forEach((item) => {
    const updatedCode = sigpacCode.replace(item.name, item.value);
    if (updatedCode !== sigpacCode) {
      sigpacCode = updatedCode;
    }
    if (item.name === AppConstants.USO_SIGPAC) {
      attributes.sigpacUse = item.value;
    }
    if (item.name === AppConstants.PENDIENTE_MEDIA) {
      attributes.sigpacSlope = item.value;
    }
  });
  attributes.sigpacCode = sigpacCode;
  return attributes;
};

// find all active fields with season id & crop id and find all the active crops
export const getAllActiveCropsDataByProperty = (
  seasonsByPropertyId,
  allCrops,
  seasonField,
  propId
) => {
  let filteredActiveFields = [];
  let cropIDwithNameArray = [];

  if (seasonField && Object.keys(seasonField).length > 0) {
    Object.keys(seasonField).forEach((key) => {
      const field = seasonField[key];
      if (
        field.property_id === propId &&
        getFieldStatusWithColor(field.start_date, field.end_date).status === 'ACTIVE' &&
        seasonsByPropertyId
      ) {
        const { crop_id } = seasonsByPropertyId?.find((s) => s.id === field.season_id) || '';
        filteredActiveFields.push({
          crop_id,
          ...field
        });
      }
    });
  }

  filteredActiveFields.forEach((field) => {
    const { crop_id } = field;
    if (allCrops[crop_id]) {
      const cropIDwithName = { id: crop_id, name: allCrops[crop_id].name };
      if (!cropIDwithNameArray.some((crop) => crop.id === crop_id))
        cropIDwithNameArray.push(cropIDwithName);
    }
  });
  return cropIDwithNameArray;
};

export const getDeepActionUrl = () => window.location.origin;

export const getDeepActionCBUrl = () => {
  return `${window.location.origin}${window.location.pathname}`;
};

let overLapfieldId = [];
function handleOverLap(overlap, boundaryFields, existingFieldsList) {
  /* will set the error code and color value to the overlapped field */
  let updatedNewFields = [...boundaryFields];
  if (boundaryFields.length > 0) {
    updatedNewFields = updatedNewFields?.filter((field) => {
      const errorred = overLapfieldId?.find((e) => field.id === e) || '';
      if (isEmpty(errorred)) {
        field.error = '';
        field.color = '#FFFFFF';
      } else {
        field.color = 'red';
        field.properties.ori_color = 'red';
        field.isOverlap = true;
        field.error = 'EGEO005';
      }
      return field;
    });
    overLapfieldId = [];
    return [...existingFieldsList, ...updatedNewFields];
  } else {
    return [...existingFieldsList];
  }
}

export function findOverLapping(existingFieldsList, boundaryFields) {
  /*
    if overlaping are found there id will be stored in an array and overlap
    is set to true.
  */
  let overlap = false;
  let overLapIdArray = [];
  const nonCheckedBoundaryFields = boundaryFields.filter((f) => !f.overlapCheck);
  try {
    for (let boundaryField of nonCheckedBoundaryFields) {
      for (let existingField of existingFieldsList) {
        if (existingField.geometry && boundaryField.geometry) {
          if (intersect(boundaryField.geometry, existingField.geometry)) {
            overlap = true;
            overLapIdArray.push(boundaryField.id);
          }
        }
      }
    }
  } catch {
    captureMessage('Problem when trying to detect overlap, invalid geometry provided', 'info');
  }
  if (overlap) {
    overLapfieldId = [...overLapIdArray];
  }
  return handleOverLap(overlap, boundaryFields, existingFieldsList);
}

export const mergeRecordsById = (arrayList = [], mergeList = []) => {
  if (arrayList.length == 0) {
    return mergeList;
  }
  const result = arrayList;
  if (arrayList.length) {
    mergeList.forEach((x) => {
      const isExist = !!arrayList.find((list) => list.id === x.id);
      if (!isExist) {
        result.push(x);
      }
    });
  }
  return result;
};

export const isMatchedAllRoutes = (routesList = []) => {
  const routesMatched = routesList.filter((r) => location.pathname.includes(r));
  return routesMatched.length === routesList.length;
};

/* function to return the fields in  mapbox required format*/
export const getFeatureCollection = (fields) => {
  const featCollection = {
    type: 'FeatureCollection',
    features: fields.filter((geo) => {
      if (geo.geometry && !isEmpty(geo.geometry)) {
        geo.type = 'Feature';
        return true;
      }
      return false;
    })
  };
  return featCollection;
};

export const getRBACWorkspaceLevelByCrn = (crn) => {
  const propertyCrn = crn.includes('property');
  const orgCrn = crn.includes('org');
  const workspaceCrn = crn.includes('workspace');
  return (
    (propertyCrn && 'property') || (orgCrn && 'org') || (workspaceCrn && 'workspace') || 'system'
  );
};

export const isAllowedResourceFromCurrentRBACLevel = (allowedResource, key, keyLevelPattern) => {
  // Resource can be equal to key or the key crn patten
  return allowedResource === key || allowedResource === keyLevelPattern;
};

export const getPatternByRoleTypeEnabled = (roleType) => {
  const PATTERN_LEVEL = {
    Workspaces: 'crn:workspace:{workspace_id}',
    Organizations: 'crn:workspace:{workspace_id}/org/{org_id}',
    Farms: 'crn:workspace:{workspace_id}/org/{org_id}/property/{property_id}'
  };
  return PATTERN_LEVEL[roleType];
};

// Get value from session
export const getSessionStorageValue = (key) => {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch {
    return false;
  }
};

// Set value to session
export const setSessionStorage = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));

export const validateUUID = (uuid) => {
  const regex = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/;
  return regex.test(uuid);
};
